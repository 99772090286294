
export const FETCH_PERMISOS_TIPO_USUARIO_REQUEST = 'FETCH_PERMISOS_TIPO_USUARIO_REQUEST';
export const FETCH_PERMISOS_TIPO_USUARIO_SUCCESS = 'FETCH_PERMISOS_TIPO_USUARIO_SUCCESS';
export const FETCH_PERMISOS_TIPO_USUARIO_FAILURE = 'FETCH_PERMISOS_TIPO_USUARIO_FAILURE';
export const CREATE_UPDATE_PERMISOS_USUARIO_REQUEST = "CREATE_UPDATE_PERMISOS_USUARIO_REQUEST";
export const CREATE_UPDATE_PERMISOS_USUARIO_SUCCESS = "CREATE_UPDATE_PERMISOS_USUARIO_SUCCESS";
export const CREATE_UPDATE_PERMISOS_USUARIO_FAILURE = "CREATE_UPDATE_PERMISOS_USUARIO_FAILURE";
export const CREATE_PERMISO_REQUEST = "CREATE_PERMISO_REQUEST";
export const CREATE_PERMISO_SUCCESS = "CREATE_PERMISO_SUCCESS";
export const CREATE_PERMISO_FAILURE = "CREATE_PERMISO_FAILURE";
