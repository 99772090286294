import { createAction } from '@reduxjs/toolkit';

export const FETCH_PLANES_REQUEST = 'FETCH_PLANES_REQUEST';
export const FETCH_PLANES_SUCCESS = 'FETCH_PLANES_SUCCESS';
export const FETCH_PLANES_FAILURE = 'FETCH_PLANES_FAILURE';
export const SET_PLANES_PAGE = 'SET_PLANES_PAGE';
export const SET_PLANES_SORT = 'SET_PLANES_SORT';
export const SET_PLANES_FILTERS = 'SET_PLANES_FILTERS';
export const CREATE_TYPE_PLANES_REQUEST = createAction('CREATE_TYPE_PLANES_REQUEST');
export const CREATE_TYPE_PLANES_SUCCESS = createAction<any>('CREATE_TYPE_PLANES_SUCCESS');
export const CREATE_TYPE_PLANES_FAILURE = createAction<string>('CREATE_TYPE_PLANES_FAILURE');
export const UPDATE_TYPE_PLANES_REQUEST = 'UPDATE_TYPE_PLANES_REQUEST';
export const UPDATE_TYPE_PLANES_SUCCESS = 'UPDATE_TYPE_PLANES_SUCCESS';
export const UPDATE_TYPE_PLANES_FAILURE = 'UPDATE_TYPE_PLANES_FAILURE';
export const DELETE_TYPE_PLANES_REQUEST = 'DELETE_TYPE_PLANES_REQUEST';
export const DELETE_TYPE_PLANES_SUCCESS = 'DELETE_TYPE_PLANES_SUCCESS';
export const DELETE_TYPE_PLANES_FAILURE = 'DELETE_TYPE_PLANES_FAILURE';
