import { createAction } from "@reduxjs/toolkit";

export const PAQUETES_MENSAJES_REQUEST = 'PAQUETES_MENSAJES_REQUEST';
export const PAQUETES_MENSAJES_SUCCESS = 'PAQUETES_MENSAJES_SUCCESS';
export const PAQUETES_MENSAJES_FAILURE = 'PAQUETES_MENSAJES_FAILURE';
export const SET_PAQUETES_MENSAJES_PAGE = 'SET_PAQUETES_MENSAJES_PAGE';
export const SET_PAQUETES_MENSAJES_SORT = 'SET_PAQUETES_MENSAJES_SORT';
export const SET_PAQUETES_MENSAJES_FILTERS = 'SET_PAQUETES_MENSAJES_FILTERS';
export const UPDATE_PAQUETES_MENSAJES_REQUEST = 'UPDATE_PAQUETES_MENSAJES_REQUEST';
export const UPDATE_PAQUETES_MENSAJES_SUCCESS = 'UPDATE_PAQUETES_MENSAJES_SUCCESS';
export const UPDATE_PAQUETES_MENSAJES_FAILURE= 'UPDATE_PAQUETES_MENSAJES_FAILURE';
export const DISABLE_PAQUETES_MENSAJES_REQUEST= 'DISABLE_PAQUETES_MENSAJES_REQUEST';
export const DISABLE_PAQUETES_MENSAJES_SUCCESS= 'DISABLE_PAQUETES_MENSAJES_SUCCESS';
export const DISABLE_PAQUETES_MENSAJES_FAILURE= 'DISABLE_PAQUETES_MENSAJES_FAILURE';
export const CREATE_PAQUETES_MENSAJES_REQUEST = createAction('CREATE_PAQUETES_MENSAJES_REQUEST');
export const CREATE_PAQUETES_MENSAJES_SUCCESS = createAction<any>('CREATE_PAQUETES_MENSAJES_SUCCESS');
export const CREATE_PAQUETES_MENSAJES_FAILURE = createAction<string>('CREATE_PAQUETES_MENSAJES_FAILURE');

