import { createAction } from '@reduxjs/toolkit';

export const FETCH_ANALYTICS_REQUEST = 'FETCH_ANALYTICS_REQUEST';
export const FETCH_ANALYTICS_SUCCESS = 'FETCH_ANALYTICS_SUCCESS';
export const FETCH_ANALYTICS_FAILURE = 'FETCH_ANALYTICS_FAILURE';
export const FETCH_GENERAL_REQUEST = 'FETCH_GENERAL_REQUEST';
export const FETCH_GENERAL_SUCCESS = 'FETCH_GENERAL_SUCCESS';
export const FETCH_GENERAL_FAILURE = 'FETCH_GENERAL_FAILURE';
export const SET_ANALYTICS_PAGE = 'SET_ANALYTICS_PAGE';
export const SET_ANALYTICS_SORT = 'SET_ANALYTICS_SORT';
export const SET_ANALYTICS_FILTERS = 'SET_ANALYTICS_FILTERS';