import React, { useContext, useEffect, useState } from 'react';
import { Card, Col, Modal, Row, Select, Skeleton } from 'antd';
import { Button, ConfigProvider } from 'antd';
import { DeleteTwoTone } from '@ant-design/icons';
import { AntDesignOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { useDispatch, useSelector } from 'react-redux';
import {
  AddCaseTrainReducer,
  GetConversacionReducer,
  GetDataChatBotsReducer,
  GetDataTrainsReducer,
} from '../../../../redux/actions/chatBots/Entrenar/ChatBots'; // Importa la acción correcta
import ChatComponent from '../../../../components/chat/ChatComponent';
import type { SelectProps } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { AppDispatch, RootState } from '../../../../redux/store/store';
import NoAccess from '../../../../components/pages/chat/NoAccess';
import { GetConversationReducer } from '../../../../redux/actions/chatBots/Chat/Chat';
import { DeleteDataConversationsReducer, GetDataConversationsReducer } from '../../../../redux/actions/chatBots/conversation/Conversation';
import { GetOneDesingChatReducer } from '../../../../redux/actions/chatBots/Chat/ChatDesing';

type LabelRender = SelectProps['labelRender'];
interface FormValues {
  fontSize: string;
  fontFamily: string;
  nombreChat: string;
  inputPlaceholder: string;
  logo: File | null;
  icono: File | null;
  iconoRuta: string;
  logoRuta: string;
  colorHeader: string;
  colorTitulo: string;
  colorEmisor: string;
  colorReceptor: string;
  estado: boolean;
  colorEstado: string;
}
const labelRender: LabelRender = (props) => {
  const { label, value } = props;

  if (label) {
    return value;
  }
  return <span>Opciones</span>;
};


const TabTrain: React.FC = () => {
  const { getPrefixCls } = useContext(ConfigProvider.ConfigContext);
  const rootPrefixCls = getPrefixCls();

  const linearGradientButton = css`
    &.${rootPrefixCls}-btn-primary:not([disabled]):not(
        .${rootPrefixCls}-btn-dangerous
      ) {
      border-width: 0;

      > span {
        position: relative;
      }

      &::before {
        content: '';
        background: linear-gradient(135deg, #6253e1, #04befe);
        position: absolute;
        inset: 0;
        opacity: 1;
        transition: all 0.3s;
        border-radius: inherit;
      }

      &:hover::before {
        opacity: 0;
      }
    }
  `;
  const [listConversationsData, setListConversationsData] = useState<any[]>([]);
  const { rex_chat_selecccionado } = useSelector(({ home }: RootState) => home);


  const { rex_conversations, rex_loading, rex_error } = useSelector((state: RootState) => state.conversation);
  const [listCasos, setListCasos] = useState([{ label: '' }]);
  const [showModalAddCase, setShowModalAddCase] = useState(false);
  const [nameCase, setNameCase] = useState('');
  const [chatData, setChatData] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [caseSelect, setCaseSelect] = useState<any>({});

  const dispatch = useDispatch<AppDispatch>();
  const { rex_chatbots, rex_list_trains } = useSelector(
    ({ chatBots }: any) => chatBots
  );

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(GetDataChatBotsReducer());
      await dispatch(GetDataTrainsReducer());
      setLoading(false);
    };
    fetchData();
  }, [dispatch]);

  const handleAddCase = async () => {
    await dispatch(AddCaseTrainReducer(nameCase));
    await dispatch(GetDataTrainsReducer());

    setShowModalAddCase(false);
  };

  const changeChatData = (content: Array<any>) => {
    setChatData(content);
  };

  const options = rex_chatbots?.map((chatbot: any) => ({
    label: chatbot.nombre,
    value: chatbot.nombre,
  }));

  useEffect(() => {

    dispatch(GetDataConversationsReducer());
  }, [rex_chat_selecccionado]);

  useEffect(() => {
    if (rex_conversations) {
      setChatData(rex_conversations[0]);
      setListConversationsData(rex_conversations);
    }
  }, [rex_conversations]);



  const handleRemove = (index: number) => {
    dispatch(DeleteDataConversationsReducer(index))
    const newList = listConversationsData.filter((_, i) => i !== index);
    setListConversationsData(newList);
  };


  const getHistoryConversation = async (id_conversation: number) => {
    const conver = await dispatch(GetConversationReducer(id_conversation, false))
    setChatData(conver)
  }
  const [initialValues, setInitialValues] = useState<FormValues>({
    fontSize: '',
    fontFamily: '',
    nombreChat: '',
    inputPlaceholder: '',
    logo: null,
    icono: null,
    iconoRuta: '',
    logoRuta: '',
    colorHeader: '#1677ff',
    colorTitulo: '#1677ff',
    colorEmisor: '#1677ff',
    colorReceptor: '#1677ff',
    estado: false,
    colorEstado: '#0BF732'
  });
  const { rex_design_chat, rex_design_status, rex_styles } = useSelector((state: RootState) => state.design);
  useEffect(() => {
    dispatch(GetOneDesingChatReducer());
  }, [dispatch, rex_styles]);
  useEffect(() => {
    if (rex_design_chat) {
      setInitialValues({
        fontSize: rex_design_chat.tamanoLetra || '',
        fontFamily: rex_design_chat.fuente || '',
        nombreChat: rex_design_chat.nombre || '',
        inputPlaceholder: rex_design_chat.placeholder || '',
        logo: null,
        icono: null,
        iconoRuta: rex_design_chat.iconoEnvio || '',
        logoRuta: rex_design_chat.logo || '',
        colorHeader: rex_design_chat.colorCabecera || '#1677ff',
        colorTitulo: rex_design_chat.colorTitulo || '#1677ff',
        colorEmisor: rex_design_chat.colorTextoEmisor || '#1677ff',
        colorReceptor: rex_design_chat.colorTextoReceptor || '#1677ff',
        estado: rex_design_chat.estado || false,
        colorEstado: rex_design_chat.colorEstado || '#0BF732'
      });
    }
  }, [rex_design_chat, rex_styles]);
  return (
    <>
      {rex_chat_selecccionado ? (
        <Card>
          <Row gutter={[24, 24]}>
            <Col xl={12} md={12}>
              <ChatComponent
                editBubble={true}
                modeBot={true}
                data={chatData}
                idConversation={caseSelect.id}
                fontSize={initialValues.fontSize}
                fontFamily={initialValues.fontFamily}
                nombreChat={initialValues.nombreChat}
                inputPlaceholder={initialValues.inputPlaceholder}
                iconoEnviarChat={initialValues.iconoRuta}
                logoChat={initialValues.logoRuta}
                estadoChat={initialValues.estado}
                coloresStyle={
                  {
                    colorCabecera: initialValues.colorHeader,
                    colorTextoEmisor: initialValues.colorEmisor,
                    colorTextoReceptor: initialValues.colorReceptor,
                    colorTitulo: initialValues.colorTitulo,
                    colorEstado: initialValues.colorEstado
                  }
                }
              />
            </Col>
            <Col xl={12} md={12}>
              {/* <Row>

                <Col xl={23} md={12} style={{ paddingRight: '10px' }}>
                  <Card
                    title={<div>Conversaciones</div>}
                    style={{ maxHeight: '600px', overflowY: 'auto' }}
                  >
                    <div>
                      {listConversationsData.map((conversation: any, index: number) => (
                        <div
                          key={conversation.id}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            borderBottom: '1px solid #C4C4C4',
                            marginBottom: '10px',
                            paddingBottom: '10px',
                          }}
                          onClick={() => {
                            getHistoryConversation(conversation.id)
                          }}
                        >
                          <div
                            style={{ width: '250px', cursor: 'pointer' }}
                          >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <div
                                style={{
                                  width: '10px',
                                  height: '10px',
                                  borderRadius: '100%',
                                  background: '#52c41a',
                                  marginRight: '10px',
                                }}
                              ></div>
                              Conversación #{index + 1}
                            </div>
                            <div>{new Date(conversation.creadoEn).toLocaleString()}</div>
                          </div>
                          <div style={{ width: '100%', textAlign: 'right' }}>
                            <DeleteTwoTone
                              onClick={() => handleRemove(index)}
                              twoToneColor="#eb2f96"
                              style={{ fontSize: '20px', cursor: 'pointer' }}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </Card>
                </Col>

              </Row> */}
              <Row style={{ marginTop: '20px' }}>
                <Col xl={24} md={24}>
                  <span>Lista de casos de entrenamiento:</span>
                  <Skeleton active loading={loading}>
                    <div>
                      {loading ? (
                        <>
                          <Skeleton.Button
                            active
                            style={{
                              width: '100%',
                              height: '40px',
                              marginBottom: '10px',
                            }}
                          />
                          <Skeleton.Button
                            active
                            style={{
                              width: '100%',
                              height: '40px',
                              marginBottom: '10px',
                            }}
                          />
                          <Skeleton.Button
                            active
                            style={{
                              width: '100%',
                              height: '40px',
                              marginBottom: '10px',
                            }}
                          />
                        </>
                      ) : rex_list_trains ? (
                        rex_list_trains?.map((caso: any, index: number) => {
                          return (
                            <div key={caso.id} style={{ marginTop: '5px' }}>
                              <ConfigProvider
                              // button={{
                              //   className: linearGradientButton,
                              // }}
                              >
                                <Button
                                  type="primary"
                                  size="large"
                                  icon={<AntDesignOutlined />}
                                  onClick={async () => {
                                    // changeChatData(mockedCases[index])
                                    setCaseSelect(caso);

                                    const conversation = await dispatch(
                                      GetConversacionReducer(caso.id)
                                    );
                                    changeChatData(conversation);
                                  }}
                                >
                                  {caso.descripcion}
                                </Button>
                              </ConfigProvider>
                            </div>
                          );
                        })
                      ) : null}
                      <div style={{ marginTop: '5px' }}>
                        <Button
                          type="primary"
                          size="large"
                          onClick={() => setShowModalAddCase(true)}
                        >
                          +
                        </Button>
                      </div>
                      <Modal
                        title="Agregar caso"
                        open={showModalAddCase}
                        onOk={handleAddCase}
                        onCancel={() => setShowModalAddCase(false)}
                        okText="Agregar"
                        cancelText="Cancelar"
                      >
                        <div style={{ margin: '20px' }} />
                        <span>Tipo de Caso:</span>
                        <TextArea
                          defaultValue={''}
                          value={nameCase}
                          onChange={(e) => setNameCase(e.target.value)}
                        />
                        {/* <div style={{margin: '20px'}} />
                      <span>Mensaje Receptor</span>
                      <TextArea
                        defaultValue={''}
                        value={nameCase}
                        onChange={(e) => setNameCase(e.target.value)}
                      /> */}
                      </Modal>
                    </div>
                  </Skeleton>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      ) : (
        <NoAccess />
      )}
    </>
  );
};

export default TabTrain;
