import { createAction } from '@reduxjs/toolkit';

export const FETCH_USUARIOS_REQUEST_USERS = 'FETCH_USUARIOS_REQUEST_USERS';
export const FETCH_USUARIOS_SUCCESS_USERS = 'FETCH_USUARIOS_SUCCESS_USERS';
export const FETCH_USUARIOS_FAILURE_USERS = 'FETCH_USUARIOS_FAILURE_USERS';
export const SET_USUARIOS_PAGE_USERS = 'SET_USUARIOS_PAGE_USERS';
export const SET_USUARIOS_SORT_USERS = 'SET_USUARIOS_SORT_USERS';
export const SET_USUARIOS_FILTERS = 'SET_USUARIOS_FILTERS';
export const UPDATE_USER_REQUEST_USERS = 'UPDATE_USER_REQUEST_USERS';
export const UPDATE_USER_SUCCESS_USERS = 'UPDATE_USER_SUCCESS_USERS';
export const UPDATE_USER_FAILURE_USERS = 'UPDATE_USER_FAILURE_USERS';
export const UPDATE_USER_STATUS_REQUEST_USERS = 'UPDATE_USER_STATUS_REQUEST_USERS';
export const UPDATE_USER_STATUS_SUCCESS_USERS = 'UPDATE_USER_STATUS_SUCCESS_USERS';
export const UPDATE_USER_STATUS_FAILURE_USERS = 'UPDATE_USER_STATUS_FAILURE_USERS';
export const CREATE_USER_REQUEST = createAction('CREATE_USER_REQUEST');
export const CREATE_USER_SUCCESS = createAction<any>('CREATE_USER_SUCCESS');
export const CREATE_USER_FAILURE = createAction<string>('CREATE_USER_FAILURE');
export const UPDATE_PLANES_REQUEST = createAction('UPDATE_PLANES_REQUEST');
export const UPDATE_PLANES_SUCCESS = createAction<any>('UPDATE_PLANES_SUCCESS');
export const UPDATE_PLANES_FAILURE = createAction<string>('UPDATE_PLANES_FAILURE');
export const PAQUETES_USERS_REQUEST = createAction('PAQUETES_USERS_REQUEST');
export const PAQUETES_USERS_SUCCESS = createAction<any>('PAQUETES_USERS_SUCCESS');
export const PAQUETES_USERS_FAILURE = createAction<string>('PAQUETES_USERS_FAILURE');
export const FETCH_PAQUETES_USUARIOS_REQUEST = 'FETCH_PAQUETES_USUARIOS_REQUEST';
export const FETCH_PAQUETES_USUARIOS_SUCCESS = 'FETCH_PAQUETES_USUARIOS_SUCCESS';
export const FETCH_PAQUETES_USUARIOS_FAILURE = 'FETCH_PAQUETES_USUARIOS_FAILURE';
