export const GET_DATA_CHATSBOTS_HOME = 'GET_DATA_CHATSBOTS_HOME'
export const BOT_SELECTED = 'BOT_SELECTED'
export const EDIT_VAR_MUNDO_HOME = 'EDIT_VAR_MUNDO_HOME'
export const GET_COUNT_CONVERSATIONS_HOME = 'GET_COUNT_CONVERSATIONS_HOME'
export const GET_COUNT_MESSAGES_HOME = 'GET_COUNT_MESSAGES_HOME'
export const GET_COUNT_MESSAGES_BY_USER_CHAT = 'GET_COUNT_MESSAGES_BY_USER_CHAT'
export const GET_COUNT_CONVERSATIONS_BY_USER_CHAT = 'GET_COUNT_CONVERSATIONS_BY_USER_CHAT'
export const GET_AVERAGE_CONVERSATIONS_MESSAGES = 'GET_AVERAGE_CONVERSATIONS_MESSAGES'
export const GET_AVERAGE_CONVERSATIONS_MESSAGES_BY_USER_CHAT = 'GET_AVERAGE_CONVERSATIONS_MESSAGES_BY_USER_CHAT'
export const GET_USER_MESSAGES_INFO_HOME = 'GET_USER_MESSAGES_INFO_HOME'

export const DELETE_CHATBOT_REQUEST = 'DELETE_CHATBOT_REQUEST';
export const DELETE_CHATBOT_SUCCESS = 'DELETE_CHATBOT_SUCCESS';
export const DELETE_CHATBOT_FAILURE = 'DELETE_CHATBOT_FAILURE';
export const GET_COUNT_TOKENS_BY_USER_CHAT = 'GET_COUNT_TOKENS_BY_USER_CHAT';

export const GET_COUNT_TOKENS_BY_USER = 'GET_COUNT_TOKENS_BY_USER';

export const GET_CHAT_SELECCIONADO_BY_USER = 'GET_CHAT_SELECCIONADO_BY_USER';
export const SET_CONVERSACION_ID_BY_USER = 'SET_CONVERSACION_ID_BY_USER';
export const SET_SUPPORT_CONVERSACION_BY_USER = 'SET_SUPPORT_CONVERSACION_BY_USER';
export const SET_TOKEN_CHAT_BY_USER = 'SET_TOKEN_CHAT_BY_USER';




