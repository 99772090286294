import { Col, Row } from 'antd'
import React from 'react'

const Chatv2 = () => {
  return (
    <>
      <Row>
        <Col xl={6}>
          <div
            style={{
              width: '100%',
              
            }}
          >

          </div>
        </Col>
        <Col xl={18}>
        
        </Col>
      </Row>
    </>
  )
}

export default Chatv2