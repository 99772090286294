import React, { useEffect } from 'react';
import { Modal, Form, Input, InputNumber, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../redux/store/store';

import { CreatePaquetesMensajesReducer, FetchPaquetesMensajesReducer } from '../../../redux/actions/paquetes_mensajes/paquetesMensajesAction';

interface CreatePaquetesMensajesModalProps {
  visible: boolean;
  onClose: () => void;
}

const CreatePaquetesMensajesModal: React.FC<CreatePaquetesMensajesModalProps> = ({ visible, onClose }) => {
  const [form] = Form.useForm();
  const dispatch: AppDispatch = useDispatch();
  const { rex_meta, rex_sortColumn, rex_sortOrder, filters } = useSelector((state: RootState) => state.paquetesMensajes);


  const handleCreatePaqueteMensaje = async () => {
    try {
      const values = await form.validateFields();
      // Ensure total_mensaje is a number
      const paqueteMensajeData = {
        ...values,
        total_mensaje: Number(values.total_mensaje)
      };
      await dispatch(CreatePaquetesMensajesReducer(paqueteMensajeData));
      message.success('Plan creado correctamente');
      onClose();
      form.resetFields();
      dispatch(FetchPaquetesMensajesReducer(
        rex_meta.page,
        rex_meta.limit,
        rex_sortColumn,
        rex_sortOrder,
        filters
      ));
    } catch (error) {
      message.error('Error al crear el plan');
      console.error('Validation failed:', error);
    }
  };

  return (
    <Modal
      title="Crear Paquete Mensajes"
      open={visible}
      onCancel={onClose}
      onOk={handleCreatePaqueteMensaje}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="paquete"
          label="Nombre del Paquete"
          rules={[{ required: true, message: 'Por favor ingrese el nombre del plan' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="total_mensaje"
          label="Total de Mensajes"
          rules={[
            { required: true, message: 'Por favor ingrese el total de mensajes' },
            { type: 'number', message: 'El valor debe ser un número' }
          ]}
        >
          <InputNumber style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          name="precio_eur"
          label="Precio en Euros"
          rules={[
            { required: true, message: 'Por favor ingrese el precio en Euros' },
            { type: 'number', message: 'El valor debe ser un número' }
          ]}
        >
          <InputNumber style={{ width: '100%' }} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreatePaquetesMensajesModal;