import React, { useEffect, useState } from 'react';
import { Tabs, Card, Input, Button, Typography, Space, Upload, Row, Checkbox, message } from 'antd';
import { InfoCircleFilled, DownOutlined, UploadOutlined } from '@ant-design/icons';
import { submitFormDataEntrenamiento, UploadTrainingData } from '../../../../redux/actions/chatBots/Chat/Chat';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../redux/store/store';
import { RcFile } from 'antd/es/upload';
import { updateChatbotBehavior } from '../../../../redux/actions/home/homeActions';


const { TabPane } = Tabs;
const { Title, Text } = Typography;

const TabKnowledgeBase: React.FC = () => {

  const dispatch: AppDispatch = useDispatch();
  
  const [file, setFile] = useState<File | null>(null);
  const [overwrite, setOverwrite] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [textInput, setTextInput] = useState(
    'Eres un vendedor de componentes de computo, mouse, teclado, cable de red, parlantes, etc.'
  );

  const {
    rex_chatbot_seleccionado
  } = useSelector(({ home }: any) => home);

  useEffect(() => {
    if (rex_chatbot_seleccionado) {
      setTextInput(rex_chatbot_seleccionado.comportamiento || '');
    }
  }, [rex_chatbot_seleccionado]);

 
  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextInput(e.target.value);
  };

  const handleFileChange = (info: any) => {
    const { file } = info;
    if (file.status === 'removed') {
      setFile(null);
    } else {
      setFile(file);
    }
  };

  const beforeUpload = (file: RcFile) => {
    const isCSV = file.type === 'text/csv';
    if (!isCSV) {
      console.error('Solo se permiten archivos CSV');
    }
    return false;
  };

  const handleCheckboxChange = (e: any) => {
    setOverwrite(e.target.checked);
  };

  const handleSubmit = async () => {
    if (!file) {
      message.error('Por favor, seleccione un archivo CSV antes de subir.');
      return;
    }

    setIsUploading(true);

    try {
      const formData = new FormData();
      formData.append('sobrescribir', overwrite.toString());
      formData.append('archivo', file);


      const formDataObject: Record<string, any> = {};
      formData.forEach((value, key) => {
        formDataObject[key] = value;
      });
      

      const data = await dispatch(submitFormDataEntrenamiento(formData));
      const response = await dispatch(UploadTrainingData(formData));

      if (response) {
        message.success('Archivo subido exitosamente');
      } else {
        message.error('Error al subir el archivo');
      }
    } catch (error) {
      console.error('Error al subir el archivo:', error);
      message.error('Ocurrió un error al subir el archivo');
    } finally {
      setIsUploading(false);
    }
  };

  const handleRetrainChatbot = async () => {
    if (!rex_chatbot_seleccionado || !rex_chatbot_seleccionado.id) {
      message.error('No hay chatbot seleccionado para actualizar.');
      return;
    }

    const success = await dispatch(updateChatbotBehavior(textInput, rex_chatbot_seleccionado.id));

    if (success) {
      message.success('El comportamiento del chatbot ha sido actualizado.');
    } else {
      message.error('Error al actualizar el comportamiento del chatbot.');
    }
  };


  const totalCharacters = textInput.length;

  return (
    <Card>
      <Title style={{ marginTop: '-5px' }} level={4}>Fuentes de Datos</Title>
      <Tabs defaultActiveKey="1" >
        <TabPane tab="Texto" key="1" >
          <Space direction="vertical" size="large" style={{ width: '100%' }}>
            <Space size="middle">
              <Title level={5} style={{ color: '#aaa', marginTop: '-5px', marginBottom: '-10px' }}>
                Datos de Texto <InfoCircleFilled style={{ color: '#aaa' }} />
              </Title>
            </Space>
            <Input.TextArea
              value={textInput}
              onChange={handleInputChange}
              rows={6}
              style={{ fontSize: '16px', color: '#aaa', marginTop: '-20px' }} // Color gris para el texto
            />
            <Space size="middle" align="center">
              <Button
                icon={<DownOutlined />}
                style={{ borderColor: '#1a81d7', backgroundColor: '#fff', color: '#1a81d7', height: '20px' }} // Azul más oscuro para el botón
              />
              <Text style={{ color: '#aaa' }}>
                Numero total de caracteres (todas las fuentes de datos): {totalCharacters}
              </Text>
            </Space>
            <Button
              type="primary"
              style={{ borderRadius: '6px', backgroundColor: '#1a81d7', borderColor: '#1a81d7', height: '40px' }}
              onClick={handleRetrainChatbot} 
            >
              Re-entrenar Chatbot
            </Button>
          </Space>
        </TabPane>
        <TabPane tab="Preguntas/Respuestas" key="2">
          <Space direction="vertical" size="middle" style={{ width: '100%' }}>
            <Card style={{ borderColor: '#d9d9d9', borderRadius: 0 }}>
              <Title level={5} style={{ color: '#aaa', marginTop: '-10px' }}>
                Upload Q/A CSV File <InfoCircleFilled style={{ color: '#aaa' }} />
              </Title>
              <Row style={{ backgroundColor: '#fff', border: 'none', borderRadius: 4 }}>
                <Upload // Usar `customRequest` para manejar la carga del archivo
                  beforeUpload={beforeUpload}
                  onChange={handleFileChange}
                  accept=".csv"

                >
                  <Button icon={<UploadOutlined />}>Seleccionar archivo</Button>
                </Upload>
              </Row>
              <Checkbox
                style={{ marginTop: '16px' }}
                onChange={handleCheckboxChange}
                checked={overwrite}
              >Sobrescribir Datos</Checkbox>
              <Space size="middle" style={{ width: '100%', marginTop: '16px', justifyContent: 'space-between' }}>
                <Button
                  onClick={handleSubmit}
                  loading={isUploading}
                  type="primary" style={{ backgroundColor: '#1a81d7', borderColor: '#1a81d7' }}>
                  Upload
                </Button>
                <Button type="link" style={{ color: '#1890ff' }}>
                  Download Example CSV
                </Button>
              </Space>
            </Card>
            <Space direction="vertical" style={{ width: '100%', alignItems: 'flex-end' }}>
              <Button
                type="primary"
                style={{ backgroundColor: '#1a81d7', borderColor: '#1a81d7', marginTop: '16px' }} // Azul más oscuro

              >
                Añadir P/A
              </Button>
              <Button
                style={{ backgroundColor: '#e6f7ff', borderColor: '#91d5ff', color: '#000', marginTop: '8px' }}
              >
                Export Q/A
              </Button>
            </Space>
          </Space>
        </TabPane>
      </Tabs>
    </Card>
  );
};

export default TabKnowledgeBase;
